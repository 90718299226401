.file-upload {
    position: relative;
    border: 1px solid rgba(122, 122, 122, 0.668);
    border-radius: 5px;
    padding: 5px;
    padding-top: 10px;
    text-align: center;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    input {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    h5 {
        width: 100px;
        vertical-align: middle;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;
    }
}

#audio-preview {
    width: 100%;
    margin-top: 18px;
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain;
}

#audio {
    width: 100%;
    height: 57px;
    background: #f1f3f4;
}
.fade:not(.show) {
    opacity: 1 !important;
}