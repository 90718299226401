.file-list {
    .file-image {
        padding: 0 40px 0 0;
        margin-top: 10px;
        cursor: pointer;
    }

    .file-name {
        font-size: 14px;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        word-wrap: break-word;
    }

    .file-content {
        position: relative;

        .delete-icon {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: #ffd0d0c4;
            border-radius: 50%;
            padding: 4px 8px;
            color: #ff3b3b;
            cursor: pointer;
        }
    }

    .add-icon {
        font-size: 28px;
        padding: 0px 10px 5px 10px;
        color: rgb(0, 102, 255);
        background-color: rgb(1 123 254 / 24%);
        border-radius: 50%;
    }
}

.breadcrumbs {
    padding: 10px;
    background-color: #aeaeae24;
    border-radius: 5px;
    label {
        margin: 0;
        cursor: pointer;
    }
}

.background-auto {
    height: calc(100vh - 137px);
    margin-top: 0 !important;
}

.right-click {
    z-index: 12;
    position: fixed;
    background-color: white;
    padding: 10px 0;
    border-radius: 8px;
    width: 200px;
    box-shadow: 0px 3px 29px 0px #0000002b;
}
